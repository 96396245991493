export default [
  {
    key: "profileImageId",
    initKey: "profileImage",
    label: "field.profileImage",
    type: "async-single-image",
    path: "testimony",
    pw: 200,
    ph: 200,
    fullWidth: false,
    disableUploadHistory: true,
    cols: 12,
  },
  {
    key: "quoteEn",
    label: "field.quoteEn",
    rules: "required|max:100",
    type: "text",
  },
  {
    key: "quoteKm",
    label: "field.quoteKm",
    rules: "required|max:100",
    type: "text",
  },
  {
    key: "nameEn",
    label: "field.nameEn",
    rules: "required|max:100",
    type: "text",
  },
  {
    key: "nameKm",
    label: "field.nameKm",
    rules: "required|max:100",
    type: "text",
  },
  {
    key: "positionEn",
    label: "field.positionEn",
    rules: "required|max:100",
    type: "text",
  },
  {
    key: "positionKm",
    label: "field.positionKm",
    rules: "required|max:100",
    type: "text",
  },
  {
    key: "isFeature",
    label: "field.feature",
    rules: "",
    type: "checkbox",
    cols: "auto",
  },
];
